export default class DxLoadOptions {

    filter = [];
    skip = 0;
    take = 10;

    constructor() {

    }

    addFilter(key) {
        if (arguments.length == 1) {
            this.filter.push(key);
        } else if (arguments.length == 3) {
            this.filter.push(Array.from(arguments));
        } else {
            throw new Error("Operator not support!");
        }

        return this;
    }

    setSkip(skip) {
        this.skip = skip;
        return this;
    }

    setTake(take) {
        this.take = take;
        return this;
    }
};