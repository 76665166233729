import { AmLich } from "./AmLich";
import Devextreme from "@sonphat/devextreme";

const AutoFocusErrorHandlers = [
    (wrapper) => {
        let input = undefined;
        if ((input = wrapper.querySelector("input.dx-texteditor-input"))) {
            input.focus();
            return true;
        }
    },
    (wrapper) => {
        let input = wrapper.querySelector("input,text-area,select");
        if (input) {
            input.focus();
            return true;
        }
    },
];

export default function Helper($Core) {
    Devextreme.$Core = $Core;
    this.autoFocusError = (result) => {
        if (
            result.brokenRules &&
            result.brokenRules.length &&
            result.brokenRules[0].validator &&
            result.brokenRules[0].validator._$element &&
            result.brokenRules[0].validator._$element.length
        ) {
            let wrapper = result.brokenRules[0].validator._$element[0];

            for (let AutoFocusErrorHandler of AutoFocusErrorHandlers) {
                if (AutoFocusErrorHandler && AutoFocusErrorHandler(wrapper)) {
                    break;
                }
            }
        }
    };

    this.getLunarDate = (dd, mm, yy) => {
        return AmLich.getLunarDate(dd, mm, yy);
    };
    this.getLunarDateText = (dd, mm, yy) => {
        let data = AmLich.getLunarDate(dd, mm, yy);
        let day = data.day < 10 ? "0" + data.day : data.day;
        let month = data.month < 10 ? "0" + data.month : data.month;
        return day + "/" + month;
    };

    this.getYearCanChiText = (yy) => {
        return AmLich.getYearCanChi(yy);
    };

    this.getToday = () => {
        let date = new Date();
        let utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0
        );
        return new Date(utc);
    };

    this.getEndDay = (date) => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            23,
            59,
            59
        );
    };

    this.getStartDay = (date) => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            0
        );
    };

    this.Table = (options_table) => {
        return Devextreme.table(options_table);
    };
    this.Excel = (options) => {
        return Devextreme.excel(options);
    };

    this.FormatTextLichChay = (x, { Tuan, ThangDuong, ThangAm }) => {
        switch (x.LoaiLichChay) {
            case ThangDuong:
                return `Lịch dương - ${x.CacNgayChayTheoLich.join(", ")}`;
            case ThangAm:
                return `Lịch âm - ${x.CacNgayChayTheoLich.join(", ")}`;
            case Tuan:
                return (
                    `Lịch tuần - ` +
                    x.CacNgayChayTheoLich.sort()
                        .map((t) => (t == 1 ? "Chủ nhật" : `Thứ ${t}`))
                        .join(", ")
                );
        }
        return "Chưa lập kế hoạch cho Nốt/Tài";
    };

    this.CheckRole = (type, key) => {
        if (!key) return true;
        let keycloak = $Core.AuthApi;
        let DanhSachQuyen =
            keycloak.TokenParsed &&
            keycloak.TokenParsed.resource_access &&
            keycloak.TokenParsed.resource_access[type] &&
            keycloak.TokenParsed.resource_access[type].roles
                ? keycloak.TokenParsed.resource_access[type].roles
                : [];
        return DanhSachQuyen.some((e) => e == key);
    };

    this.Common = Devextreme;
    this.DevExDataSource = Devextreme.DevExDataSource;
    this.DevExDataSourceCustom = Devextreme.DevExDataSourceCustom;
}
